import React, { useState } from 'react';
import FlexCardLayout from '../src/components/marketing/FlexCardLayout';
// import GridCardLayout from '../src/components/marketing/GridCardLayout';
// import MarketingCard from '../src/components/marketing/MarketingCard';
import { useRouter } from 'next/router';
import { Button } from '../src/components/shad-base/button';
import { ROUTES } from '../src/config';
import useDevice from '../src/hooks/useDevice';
import Image from '../src/components/core/atoms/Image';

import { motion } from 'framer-motion';
import MarketingSection, {
  MarketingContainer
} from '../src/components/marketing/MarketingSection';
import Layout from '../src/layouts/Layout';

export default function Page() {
  const router = useRouter();
  const { isMobile } = useDevice();
  return (
    <Layout
      title="The AI Carbon Platform"
      publicPage
      includePadding={false}
    >
      <div className="pt-xl" />
      <MarketingSection
        titlePrefix="AI-powered"
        titlePostfixes={[
          'carbon measurement',
          'carbon removal',
          'reporting',
          'decarbonization'
        ]}
        title="Your Carbon Potential Unleashed."
        subtitle="We help companies transform sustainability into profit, environmental compliance into strategic advantage, and carbon accounting into higher earnings."
        callToAction={{
          text: 'Sign up free',
          onClick: () => router.push(ROUTES.AUTH.SIGNUP)
        }}
        secondaryCallToAction={{
          text: 'Request demo',
          onClick: () => null
        }}
      >
        <div className="h-[0px] w-[0px] sm:h-[500px] sm:w-[1000px]">
          {/* <img
            src={'/static/landingpage/collages/landing/hero.svg'}
            className="min-h-[100px] w-full rounded-lg object-fill"
            /> */}
          <Image
            className="min-h-[100px] w-full rounded-lg object-fill"
            src="/static/landingpage/collages/landing/hero.svg"
            alt="hero image"
            preload={true}
            placeholderSrc="/static/landingpage/collages/landing/hero.png"
          />
        </div>
      </MarketingSection>

      {!isMobile && (
        <div className="flex w-full flex-row justify-center px-xl">
          <div className="space-around flex w-full max-w-screen-xl flex-row items-center justify-between rounded-3xl bg-card px-xl py-lg">
            <div className="max-w-[125px] ">
              <img
                src={'static/business/icons/itinerator_logo.png'}
                className="object-fill p-sm grayscale"
              />
            </div>
            <div className="max-w-[125px]">
              <img
                src={'static/business/icons/ecora_logo.png'}
                className=" object-fill p-sm grayscale"
              />
            </div>
            <div className="max-w-[125px]">
              <img
                src={'static/business/icons/teamltd_logo.png'}
                className="object-fill p-sm grayscale"
              />
            </div>
            <div className="max-w-[125px]">
              <img
                src={'static/business/icons/rwt_logo.png'}
                className="object-fill p-sm grayscale"
              />
            </div>
            <div className="max-w-[125px]">
              <img
                src={'static/business/icons/new_beginnings.png'}
                className="object-fill p-sm grayscale"
              />
            </div>
          </div>
        </div>
      )}

      <MarketingContainer>
        <MarketingSection
          title="Measure, Reduce, Report"
          type="secondary"
        >
          <FlexCardLayout
            rows={[
              {
                cards: [
                  {
                    title:
                      'Track and control carbon emissions at every level of your business, no matter its size or complexity.',
                    badges: [{ label: 'pro' }, { label: 'advanced' }],
                    imageProps: {
                      src: '/static/landingpage/collages/landing/measurement.svg',
                      alt: 'measurement collage',
                      preload: true,
                      placeholderSrc:
                        '/static/landingpage/collages/landing/measurement.png'
                    },
                    callToAction: {
                      text: 'Explore our plans',
                      onClick: () =>
                        router.push(ROUTES.RESOURCES.PRICING)
                    }
                    // type: 'secondary'
                  }
                ]
              },
              {
                cards: [
                  {
                    imageProps: {
                      src: '/static/landingpage/collages/landing/reporting.svg',
                      alt: 'reporting collage',
                      preload: true,
                      placeholderSrc:
                        '/static/landingpage/collages/landing/reporting.png'
                    },
                    imageOnly: true
                  },
                  {
                    children: <CertificationsDisplay />
                  },
                  {
                    title:
                      'World-class GHG emissions reporting for all major climate disclosure standards back by the highest quality data sources.',
                    badges: [
                      { label: 'pro +' },
                      { label: 'advanced' }
                    ],
                    callToAction: {
                      text: 'Reporting & Disclosure',
                      onClick: () =>
                        router.push(ROUTES.PRODUCTS.REPORTING)
                    }
                  }
                ]
              },
              {
                cards: [
                  {
                    title:
                      'Advanced decarbonization solutions: Set net-zero targets, model reductions, and engage suppliers effectively.',
                    badges: [{ label: 'pro' }, { label: 'advanced' }],
                    imageProps: {
                      src: '/static/landingpage/collages/landing/decarbonization.svg',
                      alt: 'decarbonization collage',
                      preload: true,
                      placeholderSrc:
                        '/static/landingpage/collages/landing/decarbonization.png'
                    },
                    callToAction: {
                      text: 'Decarbonization',
                      onClick: () =>
                        router.push(ROUTES.PRODUCTS.MEASUREMENT)
                    }
                    // type: 'secondary'
                  }
                ]
              },
              {
                cards: [
                  {
                    title:
                      'Find out how businesses across the world are managing their sustainability and connect with them.',
                    badges: [
                      { label: 'pro' },
                      { label: 'pro +' },
                      { label: 'advanced' }
                    ],
                    imageProps: {
                      src: '/static/landingpage/collages/landing/directory.svg',
                      alt: 'directory collage',
                      preload: true,
                      placeholderSrc:
                        '/static/landingpage/collages/landing/directory.png'
                    },
                    callToAction: {
                      text: 'Directory',
                      onClick: () => {
                        return;
                      }
                      // router.push(ROUTES.PRODUCTS.DIRECTORY)
                    },
                    justifyImage: 'left'
                    // type: 'secondary'
                  }
                ]
              }
            ]}
          />
        </MarketingSection>
        <MarketingSection
          title="The most capable Carbon AI on the market"
          type="secondary"
        >
          <div className="flex w-full flex-row">
            <Image
              src="/static/landingpage/collages/copilot/hero.svg"
              preload={true}
              placeholderSrc="/static/landingpage/collages/copilot/hero.png"
              alt="copilot collage"
              className="h-full w-full rounded-3xl object-cover"
            />
          </div>
          <div className="my-md flex w-full flex-row items-baseline justify-between">
            <div className="pr-xl lg:pr-0">
              <span className="text-md font-bold lg:text-3xl">
                EmitAI,{' '}
              </span>
              <span className="text-md text-gray-500 lg:text-3xl">
                centrally integrated and included in every plan.
              </span>
            </div>
            <div>
              <Button
                size="lg"
                variant="link"
                className="text-md p-0 font-bold text-black lg:text-xl"
                onClick={() => router.push(ROUTES.PRODUCTS.AI)}
              >
                Meet EmitAI {` >`}
              </Button>
            </div>
          </div>
          <div className="mt-lg flex h-full w-full flex-col gap-8 md:grid md:grid-cols-3">
            <div className="mb-md rounded-3xl bg-card p-lg">
              <p className="mb-md font-bold">
                Automated Emissions Classification
              </p>
              <p>
                EmitAI can parse your files and automatically map
                operations activity to appropriate emission factors.
              </p>
            </div>
            <div className="mb-md rounded-3xl bg-card p-lg">
              <p className="mb-md font-bold">Data Normalization</p>
              <p>
                Identify and address irregularities in large emissions
                data sets to ensure the highest quality reporting.
              </p>
            </div>
            <div className="mb-md rounded-3xl bg-card p-lg">
              <p className="mb-md font-bold">Embedded Assistant</p>
              <p>
                EmitAI gives you technical carbon accounting expertise
                on-demand via GPT style chat interface.
              </p>
            </div>
          </div>
        </MarketingSection>
        <MarketingSection
          title="Elevate Your Performance"
          type="secondary"
        >
          <FlexCardLayout
            rows={[
              {
                cards: [
                  {
                    title:
                      'Trusted by forward thinking companies around the world'
                    // callToAction: {
                    //   text: 'Customers',
                    //   onClick: () => router.push(ROUTES.COMPANY.CUSTOMERS)
                    // }
                  },
                  {
                    children: <CertificationsDisplay />
                  },
                  {
                    imageProps: {
                      src: '/static/landingpage/green-building-compressed.jpg'
                    },
                    imageQuoteUrl:
                      'static/business/icons/ecora_logo.png',
                    title:
                      '"EmitIQ has fundamentally changed the way we work"'
                  }
                ]
              },
              {
                cards: [
                  {
                    largeTitle:
                      'Discover why Ecora trusts EmitIQ as the premier choice for carbon accounting and sustainability management software.',
                    callToAction: {
                      text: 'Coming soon',
                      onClick: () => null
                    },
                    miniLogoUrl:
                      'static/business/icons/ecora_logo.png'
                  }
                ]
              },
              {
                cards: [
                  {
                    title:
                      'Make an impact and offset unavoidable emissions by supporting the highest quality offset projects.',
                    badges: [
                      { label: 'pro' },
                      { label: 'pro +' },
                      { label: 'advanced' }
                    ],
                    callToAction: {
                      text: 'Marketplace',
                      onClick: () =>
                        router.push(ROUTES.PRODUCTS.MARKETPLACE)
                    }
                  },
                  {
                    title:
                      'AI-powered disclosures get your sustainability assessment and advertising ready in hours not weeks.',
                    badges: [
                      { label: 'pro' },
                      { label: 'pro +' },
                      { label: 'advanced' }
                    ],
                    callToAction: {
                      text: 'Reporting & Disclosure',
                      onClick: () =>
                        router.push(ROUTES.PRODUCTS.REPORTING)
                    }
                  }
                ]
              }
            ]}
          />
        </MarketingSection>
      </MarketingContainer>
    </Layout>
  );
}

function CertificationsDisplay() {
  const [selectedImage, setSelectedImage] = useState(
    'static/business/icons/sbti_logo.svg'
  );

  const imageList = [
    'static/business/icons/sbti_logo.svg',
    'static/business/icons/pcaf_logo.svg',
    'static/business/icons/pfsi_sec_logo.svg',
    'static/business/icons/sasb_logo.svg',
    'static/business/icons/tcfd_logo.svg',
    'static/business/icons/secr_logo.svg'
  ];

  function swapImage() {
    setTimeout(() => {
      if (imageList.indexOf(selectedImage) == imageList.length - 1) {
        setSelectedImage(imageList[0]);
      } else {
        setSelectedImage(
          imageList[imageList.indexOf(selectedImage) + 1]
        );
      }
    }, 5000);
  }
  swapImage();
  return (
    <div className="flex h-full w-full items-center justify-center overflow-hidden">
      <motion.div
        className="w-full"
        animate={{ y: [-300, 0, 300] }}
        transition={{
          type: 'spring',
          duration: 5,
          repeat: Infinity,
          repeatDelay: 0
        }}
      >
        <div className="flex h-full flex-row justify-center">
          <img
            src={selectedImage}
            className="h-full w-3/4 object-fill px-lg grayscale"
          />
        </div>
      </motion.div>
    </div>
  );
}
